import angular from "angular";

angular
    .module('api-common')
    .factory('ClipboardCopyService', ClipboardCopyService);

function ClipboardCopyService() {


    var service = {
        copyToClipboard: copyToClipboard,

    };
    return service;

    /////////////////////////////////////////////

    async function copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text);
            console.log(`Content copied to clipboard:  ${text}`);
        } catch (err) {
            console.log(`Failed to copy: ${text}`);
        }
    }
}
