import angular from "angular";
import moment from "moment/moment";

angular
    .module('ui-common')
    .directive('localDatetime', LocalDatetimeDirective);

function LocalDatetimeDirective() {
    const directive = {
        restrict: 'A',
        scope: {
            utcDatetime: '@',
            dateFormat: '@',
            timeFormat: '@',
            showLocalTime: '@'
        },
        template: '{{ datetimeString }}',
        link: link
    };

    return directive;

    function toString(m, attrs) {
        const showLocalTime = attrs.showLocalTime !== null && attrs.showLocalTime !== undefined ? attrs.showLocalTime === 'true' : true;
        const base = showLocalTime ? m.local() : m.utc();
        return base.format(attrs.dateFormat + (attrs.timeFormat ? ' ' + attrs.timeFormat : ''));
    }

    function link(scope, element, attrs, controller) {
        scope.datetimeString = toString(moment(attrs.utcDatetime), attrs);

        scope.$watch(
            function () {
                return attrs;
            },
            function (value) {
                if (!value.utcDatetime || (isNaN(value.utcDatetime) && isNaN(Date.parse(value.utcDatetime)))) {
                    scope.datetimeString = '';
                    return;
                }

                if (isNaN(value.utcDatetime)) {
                    scope.datetimeString = toString(moment(value.utcDatetime), attrs);
                } else {
                    scope.datetimeString = toString(moment.unix(value.utcDatetime), attrs);
                }
            }, true
        );
    }
}
