import angular from "angular";
import toastr from "toastr";

angular
    .module('api-common')
    .factory('ShipmentTrackingUrlBuilderService', ShipmentTrackingUrlBuilderService);

ShipmentTrackingUrlBuilderService.$inject = ['SentinelUiSession', 'DefaultTrackingUrl', 'ClipboardCopyService'];

function ShipmentTrackingUrlBuilderService(SentinelUiSession, DefaultTrackingUrl, ClipboardCopyService) {

    var service = {
        buildTrackingUrl: buildTrackingUrl,
        copyShipmentTrackingPublicLink: copyShipmentTrackingPublicLink,
        openShipmentTrackingPageAsNewTab: openShipmentTrackingPageAsNewTab,
    };
    return service;

    /////////////////////////////////////////////

    async function buildTrackingUrl(shipment) {
        try {
            let trackUrl = `${DefaultTrackingUrl}${encodeURIComponent(shipment.referenceNumber)}`;
            const prefix = SentinelUiSession?.focus?.shipmentTrackingPrefix || SentinelUiSession?.user?.shipmentTrackingPrefix;
            if (prefix) {
                // find the third slash in the trackUrl and insert the prefix after it
                const index = trackUrl.split('/', 3).join('/').length;
                trackUrl = `${trackUrl.substring(0, index + 1)}${prefix}/${trackUrl.substring(index + 1)}`;
            }
            console.log(`Tracking Url: ${trackUrl}`);
            return trackUrl;
        } catch (err) {
            console.log(`Failed to build shipment tracking url`);
        }
    }

    async function copyShipmentTrackingPublicLink(shipment) {
        if (shipment) {
            console.log(`Shipment for TrackingURL: ${shipment}`)
            let trackUrl = await buildTrackingUrl(shipment);
            await ClipboardCopyService.copyToClipboard(trackUrl);
            toastr.options = {
                "closeButton": false,
                "debug": false,
                "newestOnTop": true,
                "progressBar": false,
                "positionClass": "toast-top-center",
                "preventDuplicates": true,
                "onclick": null,
                "showDuration": "700",
                "hideDuration": "700",
                "timeOut": "1000",
                "extendedTimeOut": "1000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            }
            toastr.success('Tracking Page Link Copied!')
        } else (
            console.log('Copy Tracking Url function given a null shipment')
        )
    }

    async function openShipmentTrackingPageAsNewTab(shipment) {
        if (shipment) {
            console.log(`Shipment for TrackingURL: ${shipment}`)
            let trackUrl = await buildTrackingUrl(shipment);
            window.open(trackUrl, '_blank');
        }
    }
}
