import angular from "angular";

angular
    .module('ui-common')
    .directive('apiDataSorter', function () {
        return {
            require: '?ngModel',
            restrict: 'E',
            replace: true,
            template: require('./data-sorter-template.html'),
            scope: {
                ngModel: '=',
                translations: '=',
                service: '=',
                columnDisplayName: '=',
                columnName: '='
            },
            link: function ($scope) {
                $scope.thisColumnIsSortedAsc = false;
                $scope.thisColumnIsSortedDesc = false;

                const update = () => {
                    $scope.thisColumnIsSortedAsc = $scope.service.sortColumn === $scope.columnName && $scope.service.sortAsc;
                    $scope.thisColumnIsSortedDesc = $scope.service.sortColumn === $scope.columnName && !$scope.service.sortAsc;
                }
                $scope.$watch('service.sortAsc', () => {
                    update();
                });
                $scope.$watch('service.sortColumn', () => {
                    update();
                });
                update();
            }
        }
    });

