import angular from "angular";

angular
    .module('ui-sentinel.watchlist')
    .controller('WatchlistDataController', WatchlistDataController);

WatchlistDataController.$inject = ['$rootScope', '$state', 'SentinelUiSession', 'WatchlistsService', 'FeedbackService', 'DatetimeValidatorService', 'SightingsAdminApiService'];

function WatchlistDataController($rootScope, $state, SentinelUiSession, WatchlistsService, FeedbackService, DatetimeValidatorService, SightingsAdminApiService) {
    var vm = {
        session: SentinelUiSession,
        feedback: FeedbackService,
        list: null,
        page: 1,
        totalPages: 1,
        totalItems: 0,
        pageArray: null,
        itemsPerPage: 500,
        fromDateVal: moment().subtract(7, 'day').startOf('day'),
        toDateVal: moment().add(1, 'day').endOf('day'),
        load: load,
        next: next,
        previous: previous,
        gotoPage: gotoPage,
        watchlist: null,
        propertyName: 'sentinelId',
        reverse: false,
        sortBy: sortBy,
        fromDate: {
            label: null,
            date: null,
            time: null,
            value: null,
            error: null
        },
        toDate: {
            label: null,
            date: null,
            time: null,
            value: null,
            error: null
        },
        toggleDateRangeForm: toggleDateRangeForm,
        submitDateRange: submitDateRange,
        resetDateRange: resetDateRange,
        refresh: refresh,
        clear: clear
    };
    activate();
    return vm;

    function activate() {
        if (typeof $state.params.watchlist !== null) {
            vm.watchlist = $state.params.watchlist;
        }
        vm.feedback.clear();
        $('#WatchlistDataUiFromTime').timepicker({ 'timeFormat': DatetimeValidatorService.localTimeFormat() });
        $('#WatchlistDataUiToTime').timepicker({ 'timeFormat': DatetimeValidatorService.localTimeFormat() });

        $rootScope.$watch(
            function () {
                return vm.fromDateVal;
            },
            function (date) {
                refreshFromDate(date);
            }, true
        );
        $rootScope.$watch(
            function () {
                return vm.toDateVal;
            },
            function (date) {
                refreshToDate(date);
            }, true
        );
    }

    function clear() {
        vm.list = [];
        vm.fromDateVal = moment().subtract(7, 'day').startOf('day');
        vm.toDateVal = moment().add(1, 'day').endOf('day');
    }

    function refresh() {
        vm.load(vm.fromDateVal, vm.reportsService.toDateVal, true);
    }

    function refreshFromDate(date) {
        if (!date) {
            vm.fromDate.label = 'null';
            vm.fromDate.value = null;
            vm.fromDate.date = null;
            vm.fromDate.time = null;
            return;
        }

        var dateMoment = moment(date).startOf('minute');
        vm.fromDate.value = dateMoment.toDate();
        vm.fromDate.date = dateMoment.format('L');
        vm.fromDate.time = dateMoment.format('LT');
        vm.fromDate.label = dateMoment.format('L LT');
    }

    function refreshToDate(date) {
        if (!date) {
            vm.toDate.label = 'null';
            vm.toDate.value = null;
            vm.toDate.date = null;
            vm.toDate.time = null;
            return;
        }

        var dateMoment = moment(date).startOf('minute');
        vm.toDate.value = dateMoment.toDate();
        vm.toDate.date = dateMoment.format('L');
        vm.toDate.time = dateMoment.format('LT');
        vm.toDate.label = dateMoment.format('L LT');
    }

    function resetDateRange() {
        vm.fromDate.error = null;
        vm.toDate.error = null;
        vm.error = null;
        refreshFromDate(vm.fromDateVal);
        refreshToDate(vm.toDateVal);
    }

    function load(fromDate, toDate, refresh) {

        if (!fromDate || !toDate) {
            clear();
            return;
        }

        var oneYearAgo = moment().subtract(1, 'year');
        var fromMoment = moment.isMoment(fromDate) ? fromDate : moment(fromDate);
        var toMoment = moment.isMoment(toDate) ? toDate : moment(toDate);
        var maxToMoment = moment(fromMoment).add(240, 'day');

        if (fromMoment.isBefore(oneYearAgo) || toMoment.isBefore(fromMoment) || toMoment.isAfter(maxToMoment)) {
            return;
        }

        if (!refresh &&
            fromMoment.isSame(vm.fromDateVal) && toMoment.isSame(vm.toDateVal)) {
            //do not reload the same data set again
            return;
        }
        vm.page = 1;
        vm.list = null;
        $rootScope.loading = true;


        var countPromise = WatchlistsService.getWatchlistLogDataCount($state.params.watchlistId, vm.itemsPerPage, fromMoment, toMoment).$promise;
        var listPromise = WatchlistsService.getWatchlistLogData($state.params.watchlistId, vm.page, vm.itemsPerPage, fromMoment, toMoment).$promise;

        countPromise.then(
            function (result) {
                vm.totalPages = result.pageCount;
                vm.totalItems = result.itemCount;

                var pageArray = [];
                for (var i = 1; i <= vm.totalPages; i++) {
                    pageArray.push(i);
                }
                vm.pageArray = pageArray;
            },
            function (error) {
                console.log(error);
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );

        listPromise.then(
            function (result) {
                vm.list = result;
            },
            function (error) {
                console.log(error);
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function submitDateRange() {
        validateDateRange();

        if (vm.fromDate.error || vm.toDate.error) {
            return;
        }

        load(vm.fromDate.value, vm.toDate.value, false);
    }

    function toggleDateRangeForm() {
        vm.fromDate.error = null;
        vm.toDate.error = null;
        vm.error = null;

        refreshFromDate(vm.fromDateVal);
        refreshToDate(vm.toDateVal);
        vm.showDateRangeForm = !vm.showDateRangeForm;
    }

    function validateDateRange() {
        vm.fromDate.error = null;
        vm.toDate.error = null;
        vm.error = null;

        var fromDateMoment = DatetimeValidatorService.toMoment(vm.fromDate.date, vm.fromDate.time);
        if (!fromDateMoment) {
            vm.fromDate.error = true;
            vm.error = 'Start ' + (DatetimeValidatorService.dateError || DatetimeValidatorService.timeError);
            return;
        }

        var toDateMoment = DatetimeValidatorService.toMoment(vm.toDate.date, vm.toDate.time);
        if (!toDateMoment) {
            vm.toDate.error = true;
            vm.error = 'End ' + (DatetimeValidatorService.dateError || DatetimeValidatorService.timeError);
            return;
        }

        var oneYearAgo = moment().subtract(364, 'days');

        if (fromDateMoment.isBefore(oneYearAgo)) {
            vm.fromDate.error = true;
            vm.error = 'Start date cannot be before ' + oneYearAgo.format('L LT');
            return;
        }

        var fortyFiveDaysAfter = moment(fromDateMoment).add(45, 'day');
        if (toDateMoment.isAfter(fortyFiveDaysAfter)) {
            vm.toDate.error = true;
            vm.error = 'End date cannot be after ' + fortyFiveDaysAfter.format('L LT');
            return;
        }

        if (toDateMoment.isSame(fromDateMoment) || toDateMoment.isBefore(fromDateMoment)) {
            vm.toDate.error = true;
            vm.error = 'End date must be after ' + fromDateMoment.format('L LT');
            return;
        }

        vm.fromDate.value = fromDateMoment.toDate();
        vm.toDate.value = toDateMoment.toDate();
    }


    function next() {
        if (vm.page !== vm.totalPages) {
            gotoPage(vm.page + 1);
        }
    }

    function previous() {
        if (vm.page !== 1) {
            gotoPage(vm.page - 1);
        }
    }

    function gotoPage(page) {

        $rootScope.loading = true;
        if (page < 1 || page > vm.totalPages) {
            return;
        }
        vm.list = null;
        vm.page = page;
        vm.errorMessage = null;


        var listPromise = WatchlistsService.getWatchlistLogData($state.params.watchlistId, vm.page, vm.itemsPerPage, null, null).$promise;

        listPromise.then(
            function (result) {
                vm.list = result;
            },
            function (error) {
                console.log(error);
                vm.list = [];
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function sortBy(propertyName) {
        vm.reverse = (vm.propertyName === propertyName) ? !vm.reverse : false;
        vm.propertyName = propertyName;
    }
}
