import angular from "angular";
import moment from "moment/moment";
import debounce from "lodash/debounce";

angular
    .module('ui-common')
    .directive('datePicker', DatePickerDirective);

const yyyymmdd = new RegExp('^\\d{4}-\\d{1,2}-\\d{1,2}$');

function DatePickerDirective() {
    return {
        require: '?ngModel',
        restrict: 'AE',
        scope: {
            ngModel: '=',
        },
        link: function (scope, elem, attrs, ngModel) {
            if (!ngModel) {
                return;
            }
            const jqElem = $(elem);

            jqElem.css('position', 'relative');
            jqElem.addClass('date-box');
            if (jqElem.width() < 160) {
                jqElem.addClass('date-box-thin');
            }

            jqElem.datepicker({
                dateFormat: 'yy-mm-dd',
                onSelect: (selectedDate) => {
                    // selectedDate is yyyy-mm-dd
                    // convert to mm/dd/yyyy (or whatever the local format is) as this is the expected
                    // format in all models
                    const modelFormatted = moment(selectedDate, 'YYYY-MM-DD').format('L');
                    ngModel.$setViewValue(modelFormatted);
                },
                beforeShow: function () {
                    setTimeout(function () {
                        $('.ui-datepicker').css('z-index', 99999999999999);
                    }, 0);
                },
            });

            let isFirstChange = true;

            const processInput = () => {
                if (isFirstChange) {
                    isFirstChange = false;
                }

                let maybeDate = scope.ngModel.replace(/\//g, '-');

                let year;
                let month;
                let day;

                if (maybeDate.match(yyyymmdd)) {
                    [year, month, day] = maybeDate.split('-');
                } else {
                    const temp = moment(scope.ngModel, 'L');
                    if (temp.isValid()) {
                        year = temp.year();
                        month = temp.month() + 1;
                        day = temp.date();
                    }
                }

                const m = moment([year, month - 1, day]);
                if (!m.isValid()) {
                    jqElem.val(scope.ngModel);
                } else {
                    jqElem.val(m.format('YYYY-MM-DD'));
                }
            };

            const debouncedProcessInput = debounce(processInput, 1000);

            scope.$watch('ngModel', () => {
                if (isFirstChange) {
                    processInput();
                } else {
                    debouncedProcessInput();
                }
            });
        }
    };
}
