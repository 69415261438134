import angular from "angular";

angular
    .module('ui-sentinel.simulators')
    .config(routes);

routes.$inject = ['$stateProvider'];

function routes($stateProvider) {
    $stateProvider
        .state('simulators', {
            abstract: true,
            url: '/simulators',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                pageTitle: 'Simulators',
                subTitle: null,
                parentState: null
            }
        })
        .state('simulators.warehouse', {
            url: '/warehouse',
            template: require('./warehouse-simulator.html'),
            data: {
                subTitle: 'Warehouse'
            }
        })
        .state('simulators.separation', {
            url: '/separation',
            template: require('./separation-simulator.html'),
            data: {
                subTitle: 'Separation'
            },
            params: {
                imei: null,
                lastReport: null
            }
        });
        /* commenting this out because the template does not exist
        .state('simulators.nearest', {
             url: '/nearest',
             template: require('./nearest-simulator.html'),
             data: {
                 subTitle: 'Nearest Sentry'
             },
             params: {
                 mac: null,
                 lastReport: null
             }
         })*/
}
