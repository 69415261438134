import angular from "angular";

// there is a prettified json copy of this value in app/assets/data/timezones.json
// that copy is just for reference, it is not used by the app
const timeZonesByGroup = [{
    "text": "Americas: ",
    "children": [{
        "id": 700,
        "offset": "UTC-09:00",
        "nOffset": -540,
        "cca2": "US",
        "name": "America/Anchorage",
        "cat": "Americas",
        "label": "(GMT-09:00) Alaska Time"
    }, {
        "id": 800,
        "offset": "UTC-08:00",
        "nOffset": -480,
        "cca2": "US",
        "name": "America/Los_Angeles",
        "cat": "Americas",
        "label": "(GMT-08:00) Pacific Time"
    }, {
        "id": 900,
        "offset": "UTC-07:00",
        "nOffset": -420,
        "cca2": "US",
        "name": "America/Denver",
        "cat": "Americas",
        "label": "(GMT-07:00) Mountain Time"
    }, {
        "id": 1000,
        "offset": "UTC-07:00",
        "nOffset": -420,
        "cca2": "US",
        "name": "America/Phoenix",
        "cat": "Americas",
        "label": "(GMT-07:00) Arizona Time"
    }, {
        "id": 1100,
        "offset": "UTC-06:00",
        "nOffset": -360,
        "cca2": "US",
        "name": "America/Chicago",
        "cat": "Americas",
        "label": "(GMT-06:00) Central Time"
    }, {
        "id": 1200,
        "offset": "UTC-05:00",
        "nOffset": -300,
        "cca2": "US",
        "name": "America/New_York",
        "cat": "Americas",
        "label": "(GMT-05:00) Eastern Time"
    }, {
        "id": 1300,
        "offset": "UTC-04:00",
        "nOffset": -240,
        "cca2": "CA",
        "name": "America/Halifax",
        "cat": "Americas",
        "label": "(GMT-04:00) Atlantic Time"
    }, {
        "id": 1400,
        "offset": "UTC-03:00",
        "nOffset": -180,
        "cca2": "AR",
        "name": "America/Argentina/Buenos_Aires",
        "cat": "Americas",
        "label": "(GMT-03:00) Buenos Aires"
    }, {
        "id": 1500,
        "offset": "UTC-03:00",
        "nOffset": -180,
        "cca2": "BR",
        "name": "America/Sao_Paulo",
        "cat": "Americas",
        "label": "(GMT-02:00) Sao Paulo"
    }],
    "firstNOffset": -540
}, {
    "text": "Europe: ",
    "children": [{
        "id": 1600,
        "offset": "UTC-01:00",
        "nOffset": -60,
        "cca2": "PT",
        "name": "Atlantic/Azores",
        "cat": "Americas",
        "label": "(GMT-01:00) Azores"
    }, {
        "id": 1700,
        "offset": "UTC+00:00",
        "nOffset": 0,
        "cca2": "GB",
        "name": "Europe/London",
        "cat": "Europe",
        "label": "(GMT+00:00) London"
    }, {
        "id": 1800,
        "offset": "UTC+01:00",
        "nOffset": 60,
        "cca2": "DE",
        "name": "Europe/Berlin",
        "cat": "Europe",
        "label": "(GMT+01:00) Berlin"
    }, {
        "id": 1900,
        "offset": "UTC+02:00",
        "nOffset": 120,
        "cca2": "FI",
        "name": "Europe/Helsinki",
        "cat": "Europe",
        "label": "(GMT+02:00) Helsinki"
    }, {
        "id": 2000,
        "offset": "UTC+03:00",
        "nOffset": 180,
        "cca2": "TR",
        "name": "Europe/Istanbul",
        "cat": "Europe",
        "label": "(GMT+03:00) Istanbul"
    }],
    "firstNOffset": 0
}, {
    "text": "Asia: ",
    "children": [{
        "id": 2100,
        "offset": "UTC+04:00",
        "nOffset": 240,
        "cca2": "AE",
        "name": "Asia/Dubai",
        "cat": "Asia",
        "label": "(GMT+04:00) Dubai"
    }, {
        "id": 2200,
        "offset": "UTC+04:30",
        "nOffset": 270,
        "cca2": "AF",
        "name": "Asia/Kabul",
        "cat": "Asia",
        "label": "(GMT+04:30) Kabul"
    }, {
        "id": 2300,
        "offset": "UTC+05:00",
        "nOffset": 300,
        "cca2": "MV",
        "name": "Indian/Maldives",
        "cat": "Asia",
        "label": "(GMT+05:00) Maldives"
    }, {
        "id": 2400,
        "offset": "UTC+05:30",
        "nOffset": 330,
        "name": "Asia/Calcutta",
        "cat": "Asia",
        "label": "(GMT+05:30) India Time"
    }, {
        "id": 2500,
        "offset": "UTC+05:45",
        "nOffset": 345,
        "cca2": "NP",
        "name": "Asia/Kathmandu",
        "cat": "Asia",
        "label": "(GMT+05:45) Kathmandu"
    }, {
        "id": 2600,
        "offset": "UTC+06:00",
        "nOffset": 360,
        "cca2": "BD",
        "name": "Asia/Dhaka",
        "cat": "Asia",
        "label": "(GMT+06:00) Dhaka"
    }, {
        "id": 2700,
        "offset": "UTC+06:30",
        "nOffset": 390,
        "cca2": "CC",
        "name": "Indian/Cocos",
        "cat": "Asia",
        "label": "(GMT+06:30) Cocos"
    }, {
        "id": 2800,
        "offset": "UTC+07:00",
        "nOffset": 420,
        "cca2": "TH",
        "name": "Asia/Bangkok",
        "cat": "Asia",
        "label": "(GMT+07:00) Bangkok"
    }, {
        "id": 2900,
        "offset": "UTC+08:00",
        "nOffset": 480,
        "cca2": "HK",
        "name": "Asia/Hong_Kong",
        "cat": "Asia",
        "label": "(GMT+08:00) Hong Kong"
    }, {
        "id": 3000,
        "offset": "UTC+09:00",
        "nOffset": 540,
        "cca2": "KP",
        "name": "Asia/Pyongyang",
        "cat": "Asia",
        "label": "(GMT+08:30) Pyongyang"
    }, {
        "id": 3100,
        "offset": "UTC+09:00",
        "nOffset": 540,
        "cca2": "JP",
        "name": "Asia/Tokyo",
        "cat": "Asia",
        "label": "(GMT+09:00) Tokyo"
    }],
    "firstNOffset": 240
}, {
    "text": "Australia: ",
    "children": [{
        "id": 3200,
        "offset": "UTC+09:30",
        "nOffset": 570,
        "cca2": "AU",
        "name": "Australia/Darwin",
        "cat": "Australia",
        "label": "(GMT+09:30) Aus Darwin"
    }, {
        "id": 3300,
        "offset": "UTC+10:00",
        "nOffset": 600,
        "cca2": "AU",
        "name": "Australia/Brisbane",
        "cat": "Australia",
        "label": "(GMT+10:00) Aus Brisbane"
    }, {
        "id": 3400,
        "offset": "UTC+10:30",
        "nOffset": 630,
        "cca2": "AU",
        "name": "Australia/Adelaide",
        "cat": "Australia",
        "label": "(GMT+10:30) Aus Adelaide"
    }, {
        "id": 3500,
        "offset": "UTC+11:00",
        "nOffset": 660,
        "cca2": "AU",
        "name": "Australia/Sydney",
        "cat": "Australia",
        "label": "(GMT+11:00) Aus Sydney"
    }],
    "firstNOffset": 570
}, {
    "text": "Pacific: ",
    "children": [{
        "id": 100,
        "offset": "UTC+12:00",
        "nOffset": 720,
        "cca2": "NR",
        "name": "Pacific/Nauru",
        "cat": "Pacific",
        "label": "(GMT+12:00) Nauru"
    }, {
        "id": 200,
        "offset": "UTC+13:00",
        "nOffset": 780,
        "cca2": "NZ",
        "name": "Pacific/Auckland",
        "cat": "Pacific",
        "label": "(GMT+13:00) Auckland"
    }, {
        "id": 300,
        "offset": "UTC+14:00",
        "nOffset": 840,
        "cca2": "KI",
        "name": "Pacific/Kiritimati",
        "cat": "Pacific",
        "label": "(GMT+14:00) Kiritimati"
    }, {
        "id": 400,
        "offset": "UTC-11:00",
        "nOffset": -660,
        "cca2": "AS",
        "name": "Pacific/Pago_Pago",
        "cat": "Pacific",
        "label": "(GMT-11:00) Pago Pago"
    }, {
        "id": 500,
        "offset": "UTC-10:00",
        "nOffset": -600,
        "cca2": "US",
        "name": "Pacific/Honolulu",
        "cat": "Pacific",
        "label": "(GMT-10:00) Hawaii Time"
    }, {
        "id": 600,
        "offset": "UTC-10:00",
        "nOffset": -600,
        "cca2": "PF",
        "name": "Pacific/Tahiti",
        "cat": "Pacific",
        "label": "(GMT-10:00) Tahiti"
    }],
    "firstNOffset": 720
}];
const timeZoneList = [];
for (const tzc of timeZonesByGroup) {
    timeZoneList.push(...tzc.children);
}

angular
    .module('sentinel.gateway.web')
    .constant('API_HOST', 'http://localhost:15931/') //TODO: since it can change, it should be moved to a service
    .constant('API_URLS', {
        token: 'token',
        clients: 'rest/1/clients'
    })
    .constant('AUTH_EVENTS', {
        loginSuccess: 'auth-login-success',
        loginFailed: 'auth-login-failed',
        logoutSuccess: 'auth-logout-success',
        sessionTimeout: 'auth-session-timeout',
        notAuthenticated: 'auth-not-authenticated',
        notAuthorized: 'auth-not-authorized'
    })
    .constant('DefaultTrackingUrl', 'https://oainsight2.onasset.com/track/')
    .constant('GoogleStaticMapApiKey', 'AIzaSyDUF8PFzcPP3a1bJtQVJiYzZgsduRXv8xc')
    .constant('HideShipmentStopTimes', false)
    .constant('TimeZonesByGroup', timeZonesByGroup)
    .constant('TimeZoneList', timeZoneList);
